import { ENUM } from '@/constants/viewTypes';

import * as schemaItemTypes from './schemaItemTypes';
import * as dictionaryTypes from './dictionaryTypes';
import { rules } from './validationRules';

/**
 * @type {Array<import('./schema').default>}
 */
export const detailsSchema = [
  {
    type: schemaItemTypes.IMAGE_LIST,
    prop: 'coverImage',
    label: 'projects.cover_image',
    editable: true,
    onlyOne: true,
  },
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        label: 'projects.type',
        prop: 'projectType',
        type: schemaItemTypes.ENUM,
        dictionary: dictionaryTypes.PROJECT_TYPES,
        size: 3,
      },
      {
        label: 'projects.buildings_number',
        prop: 'numberOfBuildings',
        type: schemaItemTypes.NUMBER,
        size: 3,
      },
      {
        label: 'projects.units_number',
        prop: 'numberOfUnits',
        type: schemaItemTypes.NUMBER,
        size: 3,
      },
      {
        label: 'projects.address',
        prop: 'address',
        type: schemaItemTypes.STRING,
        size: 3,
      },
    ],
  },
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        label: 'projects.developer',
        prop: 'developer',
        type: schemaItemTypes.STRING,
        size: 3,
      },
      {
        label: 'projects.сommunity',
        prop: 'community',
        type: schemaItemTypes.STRING,
        size: 3,
      },
    ],
  },
  {
    type: schemaItemTypes.TITLED_SECTION,
    label: 'projects.location',
    children: [
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            label: 'projects.country',
            prop: 'country',
            type: schemaItemTypes.COUNTRY,
            size: 3,
          },
          {
            label: 'projects.state',
            prop: 'state',
            type: schemaItemTypes.STRING,
            size: 3,
          },
          {
            label: 'projects.city',
            prop: 'city',
            type: schemaItemTypes.STRING,
            size: 3,
          },
          {
            label: 'projects.postal',
            prop: 'postal',
            type: schemaItemTypes.STRING,
            size: 3,
          },
        ],
      },
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            label: 'projects.company_address',
            prop: 'companyAddress',
            type: schemaItemTypes.STRING,
            size: 3,
          },
          {
            label: 'projects.location',
            prop: 'location',
            type: schemaItemTypes.STRING,
            size: 3,
          },
          {
            label: 'projects.land_number',
            prop: 'landNumber',
            type: schemaItemTypes.STRING,
            size: 3,
          },
        ],
      },
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            label: 'projects.makani_number',
            prop: 'makaniNumber',
            type: schemaItemTypes.STRING,
            size: 3,
          },
          {
            label: 'projects.plot_number',
            prop: 'plotNumber',
            type: schemaItemTypes.STRING,
            size: 3,
          },
          {
            label: 'projects.parcel_number',
            prop: 'parcelNumber',
            type: schemaItemTypes.STRING,
            size: 3,
          },
        ],
      },
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            label: 'projects.makani_number',
            prop: 'makaniNumber',
            type: schemaItemTypes.STRING,
            size: 3,
          },
          {
            label: 'projects.plot_number',
            prop: 'plotNumber',
            type: schemaItemTypes.STRING,
            size: 3,
          },
          {
            label: 'projects.parcel_number',
            prop: 'parcelNumber',
            type: schemaItemTypes.STRING,
            size: 3,
          },
        ],
      },
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            type: schemaItemTypes.DATE,
            prop: 'contractPeriodFrom',
            label: 'projects.contract_period_from',
            size: 3,
          },
          {
            type: schemaItemTypes.DATE,
            prop: 'contractPeriodTo',
            label: 'projects.contract_period_to',
            size: 3,
          },
        ],
      },
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            type: schemaItemTypes.DATE,
            prop: 'financialPeriodFrom',
            label: 'projects.financial_period_from',
            size: 3,
          },
          {
            type: schemaItemTypes.DATE,
            prop: 'financialPeriodTo',
            label: 'projects.financial_period_to',
            size: 3,
          },
        ],
      },
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            type: schemaItemTypes.BOOLEAN,
            prop: 'visitorParkingAvailable',
            label: 'projects.visitor_parking_available',
            rules: [rules.REQUIRED],
            size: 3,
          },
          {
            label: 'projects.area_unit',
            type: schemaItemTypes.ENUM,
            viewType: ENUM.radio,
            dictionary: dictionaryTypes.AREA_UNIT,
            prop: 'areaUnit',
            isAreaUnit: true,
            rules: [rules.REQUIRED],
            size: 3,
          },
        ],
      },
    ],
  },
];

export const teamSchema = [
  {
    type: schemaItemTypes.TEAM_LIST,
    prop: 'roles',
  },
];

export const notificationsSchema = [
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        label: 'notification.time_to_send',
        prop: 'at',
        type: schemaItemTypes.STRING,
      },
      {
        label: 'notification.interval',
        prop: 'repeatInterval',
        type: schemaItemTypes.STRING,
      },
      {
        label: 'notification.repetitions',
        prop: 'repeatCount',
        type: schemaItemTypes.NUMBER,
      },
    ],
  },
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        label: 'notification.recipients',
        prop: 'recipients',
        type: schemaItemTypes.STRING,
      },
    ],
  },
];
